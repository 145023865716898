<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">Pengaturan Ruangan</h3>
                    <div class="card-toolbar">
                        
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div class="mb-12">
                        <!--begin::Content-->
                        <div>
                            <div class="row">
                                <div class="col-xl-8">
                                    <button type="button" class="btn btn-primary" size="sm" style="background-color:#030A8C; border-color: #030A8C; margin-top:10px" v-b-modal.modal-tambah><i class="flaticon2-plus"></i>Tambah Ruangan</button>
                                </div>
                                <div class="col-xl-4">
                                    
                                </div>
                          </div>
                            <br>
                            <div style="overflow-x:auto;">
                                <table id="example" class="table table-striped table-bordered" style="width:100%">
                                  <thead>
                                      <tr>
                                          <th>No</th>
                                          <th>Nama Ruangan</th>
                                          <th>Status</th>
                                          <th>Aksi</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="row in ruangan" :key="row.id">
                                          <td></td>
                                          <td>{{row.nama_ruangan}}</td>
                                          <td>{{row.status}}</td>
                                          <td>
                                              <div>
                                                  <button data-toggle="modal" data-target="#modal-edit" @click="getruangan(row.id)" style="margin:5px" class="btn btn-success" v-b-tooltip.hover title="Ubah"><i class="fa fa-edit"></i></button>
                                                  <button @click="deleteruangan(row.id)" style="margin:5px" class="btn btn-danger"><i class="fa fa-trash" v-b-tooltip.hover title="Buang"></i></button>
                                                </div>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>

            <!-- Modal Tambah ruangan-->
            <b-modal
                v-model="show"
                id="modal-tambah"
                ref="modal"
                size="md"
                title="Tambah Ruangan"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk"
                >
                <form ref="form" @submit.stop.prevent="tambahruangan">
                    <div class="form-group row">
                        <label class="col-lg-4 col-form-label">Nama Ruangan</label>
                        <div class="col-lg-8">
                            <input type="text" v-model="nama_ruangan" class="form-control"/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-4">Status</label>
                        <div class="col-8">
                            <b-form-select v-model="status" :options="status_ruangan"></b-form-select>
                        </div>
                    </div>          
                </form>
                <template #modal-footer>
                    <div class="w-100">
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="background-color: #030A8C; border-color: #030A8C; color:white"
                        @click="tambahruangan"
                    >
                        Tambah
                    </b-button>
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="margin-right: 10px;"
                        @click="show=false"
                    >
                        Tutup
                    </b-button>
                    </div>
                </template>
            </b-modal>

            <!-- Modal Edit ruangan -->
                <div class="modal fade" id="modal-edit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Ubah Ruangan</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                           <form ref="form" @submit.stop.prevent="tambahcatatan">
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Nama Ruangan</label>
                                        <div class="col-lg-8">
                                            <input type="text" v-model="getRuangan.nama_ruangan" class="form-control"/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-4">Tipe</label>
                                        <div class="col-8">
                                            <b-form-select v-model="getRuangan.status" :options="status_ruangan"></b-form-select>
                                        </div>
                                    </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                            <button type="button" @click="editruangan(getRuangan.id)" style="background-color: #030A8C; border-color: #030A8C; color:white" data-dismiss="modal" class="btn btn-primary">Simpan</button>
                        </div>
                        </div>
                    </div>
                </div>

        </div>
    <!-- </div>
  </div> -->
</template>
<style>
  div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<style lang="">
  
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import { VueEditor } from "vue2-editor";
export default {
  components:{VueEditor},
  data() {
      return {
        test:'hai',
        token: localStorage.getItem('id_token'),
        url: localStorage.getItem('baseapi'),
        user: JSON.parse(localStorage.getItem('user')),
        tahun: new Date().getFullYear(),
        ruangan:[],
        nama_ruangan:'',
        getRuangan:{
            id:'',
            nama_ruangan:'',
            status:'',
            tahun:''
        },
        status:'',
        status_ruangan: [
         { value: 'Aktif', text: 'Aktif' },
         { value: 'Tidak Aktif', text: 'Tidak Aktif' },
       ],
       show:false,
      }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Pengaturan Ruangan" }]);
    this.getdata()
  },
  methods: {
    getdata(){
      $('#example').DataTable().destroy();
        this.loaddata()
    },

    // get data ruangan
    loaddata(){
      this.ruangan = null,
      axios.get(this.url+"/tabel_ruangan_pemaparan", {
            headers: {
                'xth': this.token
            }
        })
      .then(response => {
          var ruangann = response.data.data
          this.ruangan = ruangann;
          this.initDatatable();
      }).catch(error=>{
        return error
      });     
    },
    resetModal() {
        this.nama_ruangan=''
        this.status='Lock'
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.tambahruangan()
      },

    //   tambah ruangan
      tambahruangan() {
        const nama_ruangan = this.nama_ruangan;
        const status = this.status;
        const tahun = this.tahun;
        axios
            .post(this.url+"/tabel_ruangan_pemaparan",
            {
                nama_ruangan,
                status,
                tahun
            }
            , {
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Disimpan', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdata()
            return res;
            })
            .catch((err) => {
            this.$bvToast.toast('Gagal Disimpan', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-tambah')
        })
    },

    // detail ruangan
    getruangan(id){
        axios.get(this.url+"/tabel_ruangan_pemaparan?filter=id,=,"+id, {
            headers: {
                'xth': this.token
            }
        }).then(response => {
            this.getRuangan.id = id;
                this.getRuangan.nama_ruangan = response.data.data[0].nama_ruangan;
                this.getRuangan.status = response.data.data[0].status;
                this.getRuangan.tahun = response.data.data[0].tahun;
        });
    },

    // edit ruangan
    editruangan(id) {
        axios.put(this.url+`/tabel_ruangan_pemaparan/${id}`,
            {
                // idPemda,
                nama_ruangan:this.getRuangan.nama_ruangan,
                status:this.getRuangan.status,
            }
            , {
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Diedit', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdata()
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Diedit', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
    },

    // hapus ruangan
    deleteruangan(id){
        axios.delete(this.url+`/tabel_ruangan_pemaparan/${id}`,
            {
                
            }
            , {
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Dihapus', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdata()
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Dihapus', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            return err;
        });
    },
    initDatatable() {
        setTimeout(() => {
        var t =  $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300)
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
};
</script>
